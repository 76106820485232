



<template>

    <Section :options="{section:false}" class="pt-5" id="team">

         <div class="heading-block center">
            <h2>Les avocats</h2>
        </div>

        <div class="row mb-0">

            <div class="col-lg-6">

                <div class="team team-list row mb-lg-0 mb-5">
                    <div class="team-image col-sm-6">
                        <div>
                            <img src="@public/img/bensadevot/profile-01.jpg" alt="John Doe">
                            <img src="@public/img/bensadevot/profile-02.jpg" alt="Nix Maxwell">
                        </div>
                        <div class="pt-3 clearfix">
                            <a href="#" class="social-icon si-rounded si-small si-facebook">
                                <i class="icon-facebook"></i>
                                <i class="icon-facebook"></i>
                            </a>
                            <a href="#" class="social-icon si-rounded si-small si-twitter">
                                <i class="icon-twitter"></i>
                                <i class="icon-twitter"></i>
                            </a>
                            <a href="#" class="social-icon si-rounded si-small si-gplus">
                                <i class="icon-gplus"></i>
                                <i class="icon-gplus"></i>
                            </a>
                        </div>
                    </div>
                    <div class="team-desc col-sm-6">
                        <div class="team-title"><h4>Louis Bensa</h4><span>Avocat</span></div>
                        <div class="team-content">
                            <p>
                                Louis Bensa est le fils de deux avocats <br/>

Par son père le bâtonnier jean claude Bensa il est la cinquième génération à exercer la profession d’avocat au barreau de Nice <br/>

Son arrière grand oncle louis Bensa était bâtonnier dans les années 1905 1907<br/>

Son arrière grand père Denis Ciaudo était bâtonnier en 1948<br/>

Sa mère Dominique Bonnemaison Bensa a exercé dans un cabinet spécialisé en droit de la construction <br/>

L’avocature est une tradition familiale pour louis Bensa
                            </p>
                            <p>
                                Domaines d’activités dominantes :
                                <ul class="ps-4">
                                    <li>Droit du travail</li>
                                    <li>Droit de la famille</li>
                                    <li>Droit pénal</li>
                                </ul>
                            </p>
                        </div>
                        
                    </div>
                </div>

            </div>

            <div class="col-lg-6">

                <div class="team team-list row">
                    <div class="team-image col-sm-6">
                        <div>
                            <img src="@public/img/bensadevot/profile-02.jpg" alt="Nix Maxwell">
                            <img src="@public/img/bensadevot/profile-01.jpg" alt="Nix Maxwell">
                        </div>
                        <div class="pt-3 clearfix">
                            <a href="#" class="social-icon si-rounded si-small si-facebook">
                                <i class="icon-facebook"></i>
                                <i class="icon-facebook"></i>
                            </a>
                            <a href="#" class="social-icon si-rounded si-small si-twitter">
                                <i class="icon-twitter"></i>
                                <i class="icon-twitter"></i>
                            </a>
                            <a href="#" class="social-icon si-rounded si-small si-gplus">
                                <i class="icon-gplus"></i>
                                <i class="icon-gplus"></i>
                            </a>
                        </div>
                    </div>
                    <div class="team-desc col-sm-6">
                        <div class="team-title"><h4>Frederic Devot</h4><span>Avocat</span></div>
                        <div class="team-content">
                            <p>
                                Issu d’une famille de juristes niçois, Frédéric DEVOT a exercé pendant de nombreuses années aux côtés du Bâtonnier Jean-Claude Bensa qui lui a transmis ses valeurs de dignité, de conscience, d’indépendance, de probité et d’humanité, qui sont, et doivent plus que jamais, demeurer les vertus essentielles de l’avocat.<br/>
                                Diplômé d’études approfondies en droit immobilier public et privé il pourra vous conseiller, vous assister et vous défendre en matière de droit immobilier, de la construction, de la copropriété, de l’urbanisme et du foncier.
                            </p>
                        </div>
                    </div>
                </div>

            </div>

        </div>
    </Section>
    
</template>

<style>

    #team .team-image > div {
        position:relative;
    }

    #team .team-image > div > img {
        transition: opacity 0.5s;
    }

    #team .team-image > div > img:nth-child(2) {
        position:absolute;
        top:0px;
        left:0px;
    }

    #team .team-image:hover > div > img:nth-child(2) {
        opacity: 0;
    }

</style>

<script lang="ts">
import Vue from '@fwk-node-modules/vue';
import { Component } from '@fwk-node-modules/vue-property-decorator';
import Section from '@root/src/client/components/panels/layouts/canvas/Section.vue';

@Component({
  components: { 
      Section
  },
  // We add name property from store as a name computed
  computed: {}
})
export default class Team extends Vue {

    mounted() {
       
    }

    beforeDestroy() {
        
    }
    
}
</script>